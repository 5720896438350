<template>
    <v-dialog v-model="dialog">
        <template v-slot:activator="{on, attrs}">
            <v-btn v-bind="attrs" v-on="on" icon>
                <v-icon>mdi-draw-pen</v-icon>
            </v-btn>
        </template>
        <v-card>
            <MyCardTitle modulo="Assinar Sessão" :card-info="sessaoCurrent"/>
            <v-card-text>
                <v-row>
                    <v-col cols="4">
                        <pre>{{sessaoCurrent.latlng}}</pre>
                        <p>A sua distância atual do local da sessão: Km {{resultado}}</p>
                        <pre>{{paci}}</pre>
                    </v-col>
                    <v-col>
                        <MyMap :read="false" :centralizar="true" :marca="sessaoCurrent.latlng" @center="latLng" />
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>
<script>
function haversine_distance(mk1, mk2) {
    var R = 6371.0710; // Radius of the Earth in Kilometers
    var rlat1 = mk1.lat * (Math.PI/180); // Convert degrees to radians
    var rlat2 = mk2.lat * (Math.PI/180); // Convert degrees to radians
    var difflat = rlat2-rlat1; // Radian difference (latitudes)
    var difflon = (mk2.lng-mk1.lng) * (Math.PI/180); // Radian difference (longitudes)

    var d = 2 * R * Math.asin(Math.sqrt(Math.sin(difflat/2)*Math.sin(difflat/2)+Math.cos(rlat1)*Math.cos(rlat2)*Math.sin(difflon/2)*Math.sin(difflon/2)));
    return d;
}
export default {
    name:"AssinarSessao",
    components:{ MyCardTitle: () => import('../../uteis/card_title.vue'),
        MyMap: () => import('../../uteis/myMap.vue')
    },
    props: {
        sessaoCurrent: {
            type:Object,    
            default(){
                return {latLng:{lat:null, lng:null}}
            }
        }
    },
    data:() => ({
        dialog:false,
        marcas:[],
        marca:{lat: null, lng: null},
        res:null,
        paci:null
    }),
    methods:{
        latLng(item){
            this.marca = item
        },
       
    },
    mounted(){
    },
    computed:{
        resultado(){
            return haversine_distance(this.sessaoCurrent.latlng,this.marca).toFixed(3)
        }
    }
}
</script>